
      const customCSSPropertiesMap: Record<string,string> = {
        "maxContentWidth": "--awsui-max-content-width-epuq3n",
"minContentWidth": "--awsui-min-content-width-epuq3n",
"breadcrumbsGap": "--awsui-breadcrumbs-gap-epuq3n",
"contentGapLeft": "--awsui-content-gap-left-epuq3n",
"contentGapRight": "--awsui-content-gap-right-epuq3n",
"contentHeight": "--awsui-content-height-epuq3n",
"defaultMaxContentWidth": "--awsui-default-max-content-width-epuq3n",
"defaultMinContentWidth": "--awsui-default-min-content-width-epuq3n",
"drawerSize": "--awsui-drawer-size-epuq3n",
"footerHeight": "--awsui-footer-height-epuq3n",
"headerGap": "--awsui-header-gap-epuq3n",
"headerHeight": "--awsui-header-height-epuq3n",
"layoutWidth": "--awsui-layout-width-epuq3n",
"mainGap": "--awsui-main-gap-epuq3n",
"mainOffsetLeft": "--awsui-main-offset-left-epuq3n",
"mainTemplateRows": "--awsui-main-template-rows-epuq3n",
"mobileBarHeight": "--awsui-mobile-bar-height-epuq3n",
"notificationsGap": "--awsui-notifications-gap-epuq3n",
"notificationsHeight": "--awsui-notifications-height-epuq3n",
"offsetTop": "--awsui-offset-top-epuq3n",
"overlapHeight": "--awsui-overlap-height-epuq3n",
"navigationWidth": "--awsui-navigation-width-epuq3n",
"splitPanelReportedHeaderSize": "--awsui-split-panel-reported-header-size-epuq3n",
"splitPanelReportedSize": "--awsui-split-panel-reported-size-epuq3n",
"splitPanelHeight": "--awsui-split-panel-height-epuq3n",
"splitPanelMinWidth": "--awsui-split-panel-min-width-epuq3n",
"splitPanelMaxWidth": "--awsui-split-panel-max-width-epuq3n",
"toolsMaxWidth": "--awsui-tools-max-width-epuq3n",
"toolsWidth": "--awsui-tools-width-epuq3n",
"toolsAnimationStartingOpacity": "--awsui-tools-animation-starting-opacity-epuq3n",
"contentScrollMargin": "--awsui-content-scroll-margin-epuq3n",
"flashbarStackDepth": "--awsui-flashbar-stack-depth-epuq3n",
"flashbarStackIndex": "--awsui-flashbar-stack-index-epuq3n",
"flashbarStickyBottomMargin": "--awsui-flashbar-sticky-bottom-margin-epuq3n",
"stackedNotificationsBottomMargin": "--awsui-stacked-notifications-bottom-margin-epuq3n",
"stackedNotificationsDefaultBottomMargin": "--awsui-stacked-notifications-default-bottom-margin-epuq3n",
"dropdownDefaultMaxWidth": "--awsui-dropdown-default-max-width-epuq3n",
"spinnerRotatorFrom": "--awsui-spinner-rotator-from-epuq3n",
"spinnerRotatorTo": "--awsui-spinner-rotator-to-epuq3n",
"spinnerLineLeftFrom": "--awsui-spinner-line-left-from-epuq3n",
"spinnerLineLeftTo": "--awsui-spinner-line-left-to-epuq3n",
"spinnerLineRightFrom": "--awsui-spinner-line-right-from-epuq3n",
"spinnerLineRightTo": "--awsui-spinner-line-right-to-epuq3n",
"sliderLabelCount": "--awsui-slider-label-count-epuq3n",
"sliderTickCount": "--awsui-slider-tick-count-epuq3n",
"sliderReferenceColumn": "--awsui-slider-reference-column-epuq3n",
"sliderNextReferenceColumn": "--awsui-slider-next-reference-column-epuq3n",
"sliderMaxStart": "--awsui-slider-max-start-epuq3n",
"sliderMinEnd": "--awsui-slider-min-end-epuq3n",
"sliderRangeInlineSize": "--awsui-slider-range-inline-size-epuq3n",
"sliderTooltipPosition": "--awsui-slider-tooltip-position-epuq3n",
      };
      export default customCSSPropertiesMap;
    