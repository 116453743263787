import React from 'react';
import { TuxComponent } from "@amzn/tux-static-website";

interface NoAdminContentProps{

}

export default class NoAdminContent extends TuxComponent<NoAdminContentProps, any>{

    constructor(props: NoAdminContentProps){
        super(props);
        this.loadData();
    }

    async loadData(){
        console.log("In loadData");
    }

    render(){
        return (
            <></>
        );
    }
}