/* eslint no-eval: 0 */
import React from 'react';
import {TuxView} from "@amzn/tux-static-website";
import { Button, Grid, Header, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import AdminRequestHelper from "../utils/admin_request_helper";

interface TestViewProps{

}

export default class TestView extends TuxView<TestViewProps, any>{
    static DEFAULT_OPERATIONS: string = 'let result = "";\n';

    private executionResults: string = "";
    private getAuthResponse: string = "";
    
    private operations: string = TestView.DEFAULT_OPERATIONS;
    
    constructor(props: TestViewProps){
        super(props);
        this.bindAll(this);
        this.loadData();
    }

    async loadData(){
        // console.log("In loadData");
    }

    private operationsChanged(e: any){
        if (e.detail.value.length < TestView.DEFAULT_OPERATIONS.length){
            return;
        }
        this.operations = e.detail.value;
        this.dataUpdated();
    }

    private executeOperations(){
        let temp: any = "NO RESULTS";
        try{
            temp = eval(this.operations);
        }catch(e){
            temp = `${e}`;
        }
        this.executionResults = JSON.stringify(temp);
        this.dataUpdated();
    }

    async getAuthResponsePressed(){
        let resp = await AdminRequestHelper.userManagementRequest("/create-external");
        this.getAuthResponse = JSON.stringify(resp, null, 2);
        this.dataUpdated();
    }

    render(){
        return (
            <>
                <Header id="test">Test</Header>
                <SpaceBetween size="m" direction="vertical">
                    <Grid gridDefinition={[{colspan: 2}, {colspan:5,}, {colspan:5,}]}>
                        <div style={{width:"100%", height:"100%", textAlign:"center", top:"50%"}}>
                            <Button onClick={this.executeOperations}>
                                Execute
                            </Button>
                        </div>
                        <Textarea value={this.operations} placeholder="Insert code here" onChange={this.operationsChanged}/>
                        <Textarea value={this.executionResults} placeholder="Results will display here" disabled={true}/>
                    </Grid>
                    <Grid gridDefinition={[{colspan: 2}, {colspan:10}]}>
                        <div style={{width:"100%", height:"100%", textAlign:"center", top:"50%"}}>
                            <Button onClick={this.getAuthResponsePressed}>
                                Get Auth Response
                            </Button>
                        </div>
                        <Textarea value={this.getAuthResponse} placeholder="Results will display here - TEST" disabled={true}/>
                    </Grid>
                </SpaceBetween>
            </>
        );
    }
}