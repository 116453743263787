import React, { useState } from 'react';
import {  FormField, Modal,  Input, Button, SpaceBetween } from '@amzn/awsui-components-react';
import ItemTable from '../../../components/item_table'
import AdminRequestHelper from 'utils/admin_request_helper';
import { GroupType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { ItemTableController } from '../../../components/item_table';


interface UserType{
    username: string,
    givenName: string,
    familyName: string,
    email: string,
    enabled: boolean,
    status: string,
    lastModified: Date,
    createdOn: Date,
    userGroups: GroupType[]
}
interface AddUserButtonModalProps{
    callBackForModalClose: () => void;
    callBackForSearchResult: (user: UserType[]) => void;
    isOpen:boolean
}
const AddUserButtonModal = (props: AddUserButtonModalProps): JSX.Element =>{
    const [userAlias, setUserAlias] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [disableSearchButton, setDisableSearchButton] = useState(true);
    const [users, setUsers] = useState<UserType[]>([]);
    const itemTableController = new ItemTableController();

    const closeModal = () => {
        console.log('Function executed');
        setUserEmail('');
        setUserAlias('');
        setUsers([]);
        setDisableSearchButton(true)
        return props.callBackForModalClose()
      };
    const selectUsers = (selectedUsers : any[]) =>{
        props.callBackForSearchResult(selectedUsers)
        setUserEmail('');
        setUserAlias('');
        setUsers([]);
        setDisableSearchButton(true);
        itemTableController.clearSelectedItems();
    }
    
    return(
        <>
            <Modal 
                header = "Add User"
                visible={props.isOpen}
                onDismiss = {closeModal}
                >
               <SpaceBetween 
                    size="s">
                    <FormField label="User Name (Alias)">
                        <Input
                            onChange={e => {
                                setUserAlias(e.detail.value);
                                userEmail.length > 2 || e.detail.value.length > 2 ? setDisableSearchButton(false) : setDisableSearchButton(true)
                            }}
                            value={userAlias}
                            placeholder='minimum 3 characters'
                        />
                    </FormField>                
                    <FormField label="Email">
                        <Input
                            onChange={e => {
                                setUserEmail(e.detail.value);
                                e.detail.value.length > 2 || userAlias.length > 2 ? setDisableSearchButton(false) : setDisableSearchButton(true)
                            }}
                            value={userEmail}
                            placeholder='minimum 3 characters'
                        />
                    </FormField>
                    <Button
                        disabled = {disableSearchButton}
                        onClick={async () =>{
                            setIsLoading(true)
                            searchUser(userEmail, userAlias).then((users)=> {
                                setIsLoading(false)
                                setUsers(users)
                            });
                        }}>
                        Search
                    </Button> 
                    <ItemTable 
                        controller={itemTableController}
                        isLoading = {isLoading}
                        title="Search Results" 
                        items={users}
                        initialColumnOrder={['familyName','givenName']}
                        initiallyVisibleColumnCount={2}
                        disableSelection = {false}
                        actions={
                            [
                                {label:"Select", primary: true, callback: selectUsers, enableAt:1},
                            ]
                        }
                        disableSearch = {true}
                    />
                </SpaceBetween>                
            </Modal>

        </>
    )
}

async function searchUser(email:string, alias:string) : Promise<UserType[]>{
    let users : UserType[] = [];
    let url = `/searchUser?alias=${alias}&email=${email}`;
    let response = await AdminRequestHelper.userManagementRequest<any>(url);
    console.log(response);
    if (response?.message){
        users = [];
    } else {
        users = response ?? {};
    }
    return users;
}
export default AddUserButtonModal;


