import { AppLayout } from '@amzn/awsui-components-react';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import PersistentHeader from './components/frame/header';
import { PersistentHeader as StaticHeader } from '@amzn/tux-static-website';
import LeftNav from './components/frame/left_nav';
import Routing from './routing';
import {SessionHelper, UserInfoHelper} from "@amzn/tux-static-website";

interface BaseAppProps{

}

export default class BaseApp extends React.Component<BaseAppProps, any>{
    navigationOpen: boolean = true;

    render() : JSX.Element{
        return (
            <div id="base_app" style={{visibility:"hidden"}}>
                <StaticHeader/>
                <AppLayout
                    className="awsui"
                    navigation={<LeftNav/>}
                    navigationWidth={240}
                    headerSelector="#header"
                    content={<Routing/>}
                    toolsHide={true}
                />
            </div>
        );
    }
}

SessionHelper.init();
UserInfoHelper.loadUserInfoFromCookies();

ReactDOM.render(
    <BaseApp />,
  document.getElementById('root')
);