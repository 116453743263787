import { Alert, Box, Button, CollectionPreferences, CollectionPreferencesProps, Header, IconProps, Pagination, Popover, PropertyFilter, PropertyFilterProps, SpaceBetween, Table, TableProps } from '@amzn/awsui-components-react';
import React from 'react';
import {ExportToCSVButton, TuxComponent} from "@amzn/tux-static-website";
import { formatFromCamelCase, objectsAreSame } from '../utils/tools';
import StorageHelper from '../utils/storage_helper';

export interface CustomDisplayProps {
    [key: string]: (val: any, item?: any) => React.ReactNode
}

interface TableActionProps {
    label: string;
    primary?: boolean;
    icon?: IconProps.Name;
    callback: (selectedItems: any[]) => void;
    enableAt?: number;
    disable?: boolean;
}

interface ItemTablePrefs {
    pageSize: number;
    visibleColumns: string[];
}

interface ItemTableProps {
    /**
     * Items can be any object, all keys will be converted into columns.
     * 
     * The following will generate two columns, Test Column and Current Date.
     * @example
     * [
     *  {
     *     testColumn: "TEST",
     *     currentDate: new Date(),
     *  }
     * ]
     */
    items: any[];
    /**
     * Override the default display behavior for the table.
     */
    customDisplays?: CustomDisplayProps;
    title?: string;
    filteringPlaceholder?: string;
    actions?: TableActionProps[];
    controller?: ItemTableController;
    isLoading?: boolean;
    hiddenColumns?: string[];
    /**
     * Default: 10
     */
    initiallyVisibleColumnCount?: number;
    initialColumnOrder?: string[];
    selectionLimit?: number;
    initialPageSize?: number;
    disableSelection?: boolean;
    disableSearch?: boolean;
    customWidths?: { [key: string]: (string | number) };
    /**
     * Sets the value of custom columns when exporting, placed to left of exported table data
     */
    customExports?: { [key: string]: (item: any) => (string | number) };
    preferenceCacheId?: string;
    canExport?: boolean;
    counterSuffix?: string;
    customHeaders?: Map<string, string>;
    defaultSortBy?: string;
}

export class ItemTableController {
    // Queue for methods called while not bound yet
    queue: ((c: ItemTableController) => void)[] = [];
    table: ItemTable | null = null;


    bind(table: ItemTable) {
        // console.log("Binding to table - " + table);
        this.table = table;
        // console.log(this.table);
        this.queue.forEach((q) => q(this));
    }

    setIsLoading(isLoading: boolean = true) {
        if (this.table) {
            this.table.setIsLoading(isLoading);
        } else {
            this.queue.push((c) => c.setIsLoading(isLoading));
        }
    }
    clearSelectedItems(){
        if (this.table) {
          this.table.selectedItems = [];
        } else {
          this.queue.push((c) => c.clearSelectedItems());
        }
      }
}

export default class ItemTable extends TuxComponent<ItemTableProps, any>{
    private static tableCounter = 0;
    private _controller?: ItemTableController;
    private _id: string;
    private _items: any[];
    private sortingField: string = this.props.defaultSortBy ?? "";
    private isDescending: boolean = false;
    // private filteringText = "";
    private pageSize: number = this.props.initialPageSize ?? 100;
    private currentPage: number = 1;
    selectedItems: any[] = [];
    private columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
    private isLoading: boolean = false;
    private isShowingAlert: boolean = false;
    private inputPrefs: ItemTablePrefs;
    private propertyFilterQuery: PropertyFilterProps.Query = {
        tokens: [],
        operation: "and"
    };
    private shouldUpdateFilter: boolean = true;
    private filteredItems: any[] = [];
    private filteringOptions: PropertyFilterProps.FilteringOption[] = [];
    public visibleColumns: string[] = [];

    constructor(props: ItemTableProps) {
        super(props);
        ItemTable.tableCounter++;
        this._id = `${this.constructor.name}_${ItemTable.tableCounter}`;
        // Replaces all calls to bind
        this.bindAll(this);

        this._items = this.props.items;
        this.props.controller?.bind(this);
        this._controller = this.props.controller;
        this.columnDefinitions = this.generateColumnDefinitions();
        this.isLoading = this.props.isLoading != null ? this.props.isLoading : false;

        this.inputPrefs = {
            pageSize: this.props.initialPageSize ?? 100,
            visibleColumns: this.props.initialColumnOrder ?? this.visibleColumns,
        };

        if (this.props.preferenceCacheId) {
            let cachedPrefs = StorageHelper.getObject<ItemTablePrefs>(this.props.preferenceCacheId, this.inputPrefs);

            if (cachedPrefs) {
                this.pageSize = cachedPrefs.pageSize ?? this.pageSize;
                this.visibleColumns = cachedPrefs.visibleColumns ?? this.visibleColumns;
            }

        }

    }

    async loadData() {
        // Does nothing
    }

    /**
     * Get the set of items, after filtering
     */
    get items() {
        return this.filter();
    }

    componentDidUpdate(oldProps: ItemTableProps) {
        if (!objectsAreSame(oldProps, this.props)) {
            this.updateFromProps();
        }
    }

    private updateFromProps() {
        this._items = this.props.items;
        this.sort();
        this.props.controller?.bind(this);
        this._controller = this.props.controller;
        this.columnDefinitions = this.generateColumnDefinitions();
        this.isLoading = this.props.isLoading != null ? this.props.isLoading : false;

        this.inputPrefs = {
            pageSize: this.props.initialPageSize ?? 100,
            visibleColumns: this.props.initialColumnOrder ?? this.visibleColumns,
        };

        if (this.props.preferenceCacheId) {
            let cachedPrefs = StorageHelper.getObject<ItemTablePrefs>(this.props.preferenceCacheId, this.inputPrefs);

            if (cachedPrefs) {
                this.pageSize = cachedPrefs.pageSize ?? this.pageSize;
                this.visibleColumns = cachedPrefs.visibleColumns ?? this.visibleColumns;
            }

        }

        this.dataUpdated();
    }

    public setItems(items: any[]) {
        this._items = items;
        this.columnDefinitions = this.generateColumnDefinitions();
        this.dataUpdated();
    }


    public setIsLoading(isLoading: boolean = true) {
        this.isLoading = isLoading;
        // console.log("About to generateColumns from setIsLoading");
        this.columnDefinitions = this.generateColumnDefinitions();
        this.dataUpdated();
    }

    private generateColumnDefinitions() {
        let output: TableProps.ColumnDefinition<any>[] = [];
        let headers: Set<string> = new Set<string>();
        let seenKeys: Set<string> = new Set<string>();
        let hiddenColumns: Set<string> = this.props.hiddenColumns ? new Set<string>(this.props.hiddenColumns) : new Set<string>();

        for (let item of this._items) {
            for (let key of (this.props.initialColumnOrder ?? []).concat(Object.keys(item)) ) {
                if (typeof item[key] === 'function' || hiddenColumns.has(key) || key.startsWith("_")) {
                    continue;
                }

                // If this key has already been seen, skip it
                if (seenKeys.has(key)){
                    continue;
                }
                seenKeys.add(key);

                // If visible column count hasn't been exceeded and it isn't already present, add this column
                if (this.visibleColumns.length < (this.props.initiallyVisibleColumnCount ?? 10) && !this.visibleColumns.includes(key)) {
                    this.visibleColumns.push(key);
                }

                // Swapped camelCase for Camel Case
                let header = formatFromCamelCase(key);
                
                if(typeof this.props.customHeaders !== 'undefined'){
                    header = this.props.customHeaders.get(key)?? formatFromCamelCase(key);
                }

                if (headers.has(header)) {
                    continue;
                }
                headers.add(header);

                output.push({
                    width: (this.props.customWidths ?? {})[key] ?? `calc(${(1 / Math.min((this.props.initiallyVisibleColumnCount ?? 10), Object.keys(item).length)) * 100})%`,
                    id: key,
                    header: header,
                    cell: (e) => (this.props.customDisplays != null && this.props.customDisplays[key] != null) ? this.props.customDisplays[key](e[key], e) : (e[key] != null ? `${e[key]}` : "-"),
                    sortingField: key,
                    sortingComparator: (a, b) => `${a}`.localeCompare(`${b}`),
                } as TableProps.ColumnDefinition<any>);
            }
        }

        // console.log("Visible Columns (generateColumnDefinitions): " + this.visibleColumns);
        this.filteringOptions = this.getFilteringOptions();

        return output;
    }

    /**
     * TODO: Allow custom comparator
     */
    private sort() {
        if (this.sortingField.length === 0) {
            if (process.env.NODE_ENV !== 'production') {
                console.log("(DEBUG) Attempted to sort by empty column");
            }
            return;
        }
        this._items.sort((a: any, b: any) => {
            let _a = a[this.sortingField];
            let _b = b[this.sortingField];
            let aVal = _a != null ? _a : '';
            let bVal = _b != null ? _b : '';
            if (typeof aVal === 'string' && typeof bVal === 'string') {
                return aVal.localeCompare(bVal);
            }
            return aVal < bVal ? -1 : aVal === bVal ? 0 : 1;
        });
        if (this.isDescending) {
            this._items.reverse();
        }
    }

    /**
     * 
     * @param text Text to search
     * @param operator Operation to perform
     * @param value Text entered in search 
     * @returns 
     */
    private validateOperation(text: string, operator: PropertyFilterProps.ComparisonOperator, value: string): boolean {
        if (typeof text !== "string"){
            text = `${text}`;
        }
        if (!text) {
            return false;
        }
        const isList = value.includes(";");
        switch (operator) {
            case "=":
                return text === value;
            case "!=":
                return text !== value;
            case ":":
                if (isList){
                    return (!!value.split(";").find((v) => v.includes(text) || text.includes(v)));
                }
                return text.includes(value);
            case "!:":
                return !text.includes(value);
        }
        if (process.env.NODE_ENV !== 'production') {
            console.log("(DEBUG)", "Operator doesn't match any valid options");
        }
        return false;
    }

    private filter(): any[] {
        if (this.propertyFilterQuery.tokens.length === 0) {
            return this._items;
        }

        if (!this.shouldUpdateFilter) {
            return this.filteredItems;
        }

        // Copy items
        let output: any[] = [];
        if (this.propertyFilterQuery.operation === "and") {
            // And filter, remove when doesn't match

            for (let item of this._items) {
                let doesMatchFilter = true;
                for (let token of this.propertyFilterQuery.tokens) {
                    if (!this.validateOperation(item[token.propertyKey ?? ""], token.operator, token.value)) {
                        doesMatchFilter = false;
                        break;
                    }
                }
                if (doesMatchFilter) {
                    output.push(item);
                }
            }

        } else {
            // or filter
            for (let item of this._items) {
                let doesMatchFilter = false;
                for (let token of this.propertyFilterQuery.tokens) {
                    if (this.validateOperation(item[token.propertyKey ?? ""], token.operator, token.value)) {
                        doesMatchFilter = true;
                        break;
                    }
                }
                if (doesMatchFilter) {
                    output.push(item);
                }
            }
        }

        this.shouldUpdateFilter = false;
        this.filteredItems = output;
        return output;
    }

    private resetPrefsPressed() {
        this.pageSize = this.inputPrefs.pageSize;
        this.visibleColumns = this.inputPrefs.visibleColumns;
        if (this.props.preferenceCacheId) {
            StorageHelper.setObject(this.props.preferenceCacheId, {
                pageSize: this.pageSize,
                visibleColumns: this.visibleColumns,
            } as ItemTablePrefs);
        }

        // Trigger the cancel button, there's no way to manually dismiss the overlay and ok will trigger the callback
        let body = document.getElementsByTagName("body")[0];
        if (body.childNodes.length === 1) {
            return;
        }
        let modalDiv = body.childNodes[body.childNodes.length - 1] as HTMLElement;

        let buttons = Array.from(modalDiv.getElementsByTagName("button"));
        for (let button of buttons) {
            if (button.className.includes("cancel")) {
                button.click();
            }
        }

        this.dataUpdated();
    }

    private getCustomPrefs() {
        if (!this.props.preferenceCacheId) {
            return null
        }
        return (
            <Popover triggerType="custom" content="Hit Cancel, to confirm">
                <Button onClick={this.resetPrefsPressed}>Reset Preferences</Button>
            </Popover>
        )
    }

    private getVisiblePrefs(): CollectionPreferencesProps.VisibleContentPreference {
        return {
            title: "Select visible columns",
            options: [
                {
                    label: "Main properties",
                    options: this.columnDefinitions.map((c) => {
                        return {
                            id: c.id,
                            label: c.header,
                        } as CollectionPreferencesProps.VisibleContentOption;
                    })
                }
            ]
        };
    }

    private getPreferences() {
        let _prefs: CollectionPreferencesProps.Preferences = {
            pageSize: this.pageSize,
            visibleContent: this.visibleColumns,
        };

        let _pagePrefs: CollectionPreferencesProps.PageSizePreference = {
            title: "Select page size",
            options: [
                { value: 10, label: "10 items per page" },
                { value: 20, label: "20 items per page", },
                { value: 40, label: "40 items per page" },
                { value: 100, label: "100 items per page" },
                { value: 200, label: "200 items per page" },
                { value: 400, label: "400 items per page" },
            ]
        };

        return (
            <CollectionPreferences
                id={`${this._id}_preferences`}
                title="Table Settings"
                confirmLabel="Ok"
                cancelLabel="Cancel"
                preferences={_prefs}
                pageSizePreference={_pagePrefs}
                visibleContentPreference={this.getVisiblePrefs()}
                customPreference={this.getCustomPrefs}
                onConfirm={this.onPreferencesChange}
            />
        );
    }

    private getButtonFromAction(a: TableActionProps) {
        return (
            <Button
                iconName={a.icon}
                variant={a.primary ? "primary" : undefined}
                key={`${a.label + "_" + Math.floor(Math.random() * 10000)}`}
                onClick={() => a.callback(this.selectedItems)}
                children={a.label} // Use this so the trailing tag isn't needed
                disabled={a.disable || (a.enableAt ? this.selectedItems.length < a.enableAt : false)}
                
            />);
    }

    public sortBy(column: string){
        this.sortingField = column;
        this.dataUpdated();
    }

    private onSortingChange(e: any) {
        if (process.env.NODE_ENV !== 'production') {
            console.log("(DEBUG)", e);
        }

        let newField = e.detail.sortingColumn.sortingField;

        // Field was toggled
        if (newField === this.sortingField) {
            this.isDescending = !this.isDescending;
        }

        this.sortingField = newField;
        this.sort();
        this.shouldUpdateFilter = true;
        this.dataUpdated();
    }

    private onPreferencesChange(e: any) {
        if (process.env.NODE_ENV !== 'production') {
            console.log("(DEBUG)", e);
        }
        this.pageSize = e.detail.pageSize;
        this.visibleColumns = e.detail.visibleContent;
        if (process.env.NODE_ENV !== 'production') {
            console.log("(DEBUG)" + this.visibleColumns);
        }

        if (this.props.preferenceCacheId) {
            StorageHelper.setObject(this.props.preferenceCacheId, {
                pageSize: this.pageSize,
                visibleColumns: this.visibleColumns,
            } as ItemTablePrefs);
        }

        this.filteringOptions = this.getFilteringOptions();

        this.dataUpdated();
    }

    private onPaginationChange(e: any) {
        if (process.env.NODE_ENV !== 'production') {
            console.log(e);
        }
        this.currentPage = e.detail.currentPageIndex;
        this.dataUpdated();
    }

    private onSelectionChange(e: any) {
        // if (process.env.NODE_ENV !== 'production') {
        //     console.log("(DEBUG)", e);
        // }
        if (this.selectedItems.length > 0 && this.selectedItems.length !== this.pageSize - 1 && e.detail.selectedItems.length === this.pageSize) {
            this.selectedItems = [];
            this.isShowingAlert = false;
            this.dataUpdated();
            return;
        }

        if (e.detail.selectedItems.length > (this.props.selectionLimit ?? (this.items.length + 1))) {

            this.isShowingAlert = true;
            this.dataUpdated();
            return;
        }
        this.selectedItems = e.detail.selectedItems;
        this.dataUpdated();
    }

    private getPagination() {
        let actions = [];
        if (this.props.title === undefined){
            actions.push(this.props.actions?.map(this.getButtonFromAction) ?? []);
            if (this.props.canExport) {
                actions?.push(this.getExportButton());
            }
        }

        return (
            <SpaceBetween size="s" direction="horizontal">
                {actions}
                <Pagination
                    currentPageIndex={this.currentPage}
                    pagesCount={Math.ceil(this.items.length / this.pageSize)}
                    ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                    }}
                    onChange={this.onPaginationChange}
                />
            </SpaceBetween>
        );
    }

    private hideAlert() {
        this.isShowingAlert = false;
        this.dataUpdated();
    }

    /**
     * Get the exportable items formatted with custom items
     * @returns List of items with custom export formatting
     */
    private getExportables(): any[] {
        let output: any[] = [];

        for (let item of (this.props.disableSelection ? this.items : this.selectedItems)) {
            let temp: any = {};
            // Only copy visible columns, more efficient
            for (let key of this.visibleColumns) {
                temp[key] = item[key];
            }

            for (let key in this.props.customExports) {
                temp[key] = this.props.customExports[key](item);
            }

            output.push(temp);
        }

        return output;
    }

    private getExportButton(): JSX.Element {
        return (
            <ExportToCSVButton
                key={`EXPORT_TO_CSV_${this._id}`}
                keys={Object.keys(this.props.customExports ?? {}).concat(this.visibleColumns)}
                data={this.getExportables()}
                disabled={this.props.disableSelection ? false : (this.selectedItems.length === 0)}
                filename={`${this.props.title ?? "table_export"}.csv`}
            />
        );
    }

    private getHeader(): JSX.Element {
        if (this.props.title === undefined) {
            return (<></>);
        }

        let actions = this.props.actions?.map(this.getButtonFromAction) ?? [];
        if (this.props.canExport) {
            actions?.push(this.getExportButton());
        }


        return (
            <Header
                counter={`(${this.props.items ? this.items.length : 0}${this.props.counterSuffix ?? ""})`}
                actions={
                    <SpaceBetween size="xs" direction="horizontal">
                        {actions}
                        {this.props.disableSearch ? <>
                            {this.getPagination()}
                            {this.getPreferences()}
                        </> : null}
                    </SpaceBetween>
                }
            >{this.props.title}</Header>
        );
    }

    private getFilteringOptions(): PropertyFilterProps.FilteringOption[] {
        let output: PropertyFilterProps.FilteringOption[] = [];
        let outputValues: { [key: string]: Set<any> } = {};

        for (let key of this.visibleColumns){
            outputValues[key] = new Set<any>();
        }

        for (let item of this._items) {
            for (let key of this.visibleColumns) {
                if (item[key] === undefined || item[key] === null) {
                    continue;
                }
                if (outputValues[key].has(item[key])) {
                    continue;
                }
                outputValues[key].add(item[key]);
                output.push({
                    propertyKey: key,
                    value: `${item[key]}`,
                });
            }
        }

        return output;
    }

    private getFilteringProperties(): PropertyFilterProps.FilteringProperty[] {
        let output: PropertyFilterProps.FilteringProperty[] = [];
        for (let key of this.visibleColumns) {
            output.push({
                key: key,
                propertyLabel: this.columnDefinitions.find((colDef) => colDef.id === key)?.header?.toString() ?? key,
                groupValuesLabel: `${this.columnDefinitions.find((colDef) => colDef.id === key)?.header?.toString() ?? key} values`,
                operators: [
                    "=",
                    "!=",
                    ":",
                    "!:",
                ],
            })
        }
        return output;
    }

    private onPropertyFilterChange(newFilterQuery: PropertyFilterProps.Query) {
        this.propertyFilterQuery = newFilterQuery;
        this.shouldUpdateFilter = true;
        this.dataUpdated();
    }

    private getFilter() {
        return (
            <PropertyFilter
                onChange={({ detail }) => this.onPropertyFilterChange(detail)}
                query={this.propertyFilterQuery}
                filteringProperties={this.getFilteringProperties()}
                filteringOptions={this.filteringOptions}
                virtualScroll
                tokenLimit={3}
                disableFreeTextFiltering
                i18nStrings={{
                    filteringAriaLabel: "your choice",
                    dismissAriaLabel: "Dismiss",
                    filteringPlaceholder: "Filter",
                    groupValuesText: "Values",
                    groupPropertiesText: "Properties",
                    operatorsText: "Operators",
                    operationAndText: "and",
                    operationOrText: "or",
                    operatorLessText: "Less than",
                    operatorLessOrEqualText: "Less than or equal",
                    operatorGreaterText: "Greater than",
                    operatorGreaterOrEqualText: "Greater than or equal",
                    operatorContainsText: "Contains",
                    operatorDoesNotContainText: "Does not contain",
                    operatorEqualsText: "Equals",
                    operatorDoesNotEqualText: "Does not equal",
                    editTokenHeader: "Edit filter",
                    propertyText: "Property",
                    operatorText: "Operator",
                    valueText: "Value",
                    cancelActionText: "Cancel",
                    applyActionText: "Apply",
                    allPropertiesLabel: "All properties",
                    tokenLimitShowMore: "Show more",
                    tokenLimitShowFewer: "Show fewer",
                    clearFiltersText: "Clear filters",
                    removeTokenButtonAriaLabel: () => "Remove token",
                    enteredTextLabel: text => `Use: "${text}"`,
                }}
            />
        );
    }

    getEmptyDisplay(): React.ReactNode {
        return (
            <Box textAlign="center" color="inherit">
                <b>{this.propertyFilterQuery.tokens.length > 0 ? "No Matches" : "No Items"}</b>
                <Box
                    padding={{ bottom: "s" }}
                    variant="p"
                    color="inherit"
                >
                    {this.propertyFilterQuery.tokens.length > 0 ? "No items match your filter" : "No items to display"}
                </Box>
            </Box>
        );
    }

    render() {
        return (
            <>
                <Alert visible={this.isShowingAlert} dismissible onDismiss={this.hideAlert} header={`You can only select ${this.props.selectionLimit} items from the table.`} />
                {this.isShowingAlert ? <br /> : null}
                <Table
                    id={this._id}
                    items={this.items.slice(this.pageSize * (this.currentPage - 1), this.pageSize * (this.currentPage - 1) + this.pageSize)}
                    visibleColumns={this.visibleColumns}
                    columnDefinitions={this.columnDefinitions}
                    resizableColumns={true}
                    selectionType={!this.props.disableSelection ? "multi" : undefined}
                    selectedItems={this.selectedItems}
                    onSelectionChange={this.onSelectionChange}
                    loading={this.isLoading}
                    loadingText={"Loading..."}
                    pagination={this.props.disableSearch ? undefined : this.getPagination()}
                    sortingColumn={{
                        sortingField: this.sortingField,
                        sortingComparator: (a, b) => `${a}`.localeCompare(`${b}`)
                    }}
                    filter={this.props.disableSearch ? undefined : this.getFilter()}
                    header={this.getHeader()}
                    empty={this.getEmptyDisplay()}
                    preferences={this.props.disableSearch ? undefined : this.getPreferences()}
                    sortingDescending={this.isDescending}
                    onSortingChange={this.onSortingChange}
                />
            </>
        );

    }
}