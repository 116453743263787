import CookieHelper from "./cookie_helper";
import { CookieNames } from "@amzn/tux-static-website/dist/utils/cookie_helper";

export class DailyErrorsSummary implements DailyErrorsSummaryInf{
    _id: DMYId;
    errors: ErrorSummaryEntry[];
    constructor(_id:DMYId, errors: ErrorSummaryEntry[]){
        this._id = _id;
        this.errors = errors;
    }

    static fromJSON(...values: any[]): DailyErrorsSummary[]{
        if (Array.isArray(values[0])){
            values = values[0];
        }
        if (values.length === 0 || Object.keys(values[0]).length === 0){
            return [];
        }
        let output: DailyErrorsSummary[] = [];

        for (let value of values){
            output.push(new DailyErrorsSummary(
                {
                    day: value["_id"]["day"],
                    month: value["_id"]["month"],
                    year: value["_id"]["year"]
                },
                value["errors"]
            ))
        }

        return output;
    }
}

export interface DMYId{
    day: number;
    month: number;
    year: number;
}

export interface ErrorSummaryEntry {
    href: string;
    error: string;
    user: string;
    date_recorded: string;
}

export interface DailyErrorsSummaryInf{
    _id: DMYId;
    errors: ErrorSummaryEntry[];
}

export interface MonthlyUsersResponse {
    _id: {
        month: number,
        year: number
    };
    users: string[];
}

export interface DailyVisitsSummary {
    _id: DMYId;
    visits: number;
}

export interface DailyPageVisitsSummary {
    _id: {
        page: string,
        day: number,
        month: number,
        year: number
    },
    count: number
}

export default class AdminRequestHelper {
    public static ANALYTICS_HOST = (process.env.NODE_ENV !== 'production' || window.location.hostname.includes("localhost")) ? "https://analytics.dev.tux.eng-plm.a2z.com" : `https://${window.location.hostname.startsWith("ui") ? window.location.hostname.replace("ui", "analytics") : (`analytics.${window.location.hostname}`)}`;

    public static get USER_MANAGEMENT_API() {
        return process.env.NODE_ENV !== 'production' ? "https://user-management.dev.tux.eng-plm.a2z.com" : `https://user-management.${window.location.hostname.replace(/(ui\.)|(admin\.)/, "")}`;
    }

    public static async userManagementRequest<T>(url:string, opts?:any): Promise<T>{
        if (!url.startsWith("/")){
            url = "/" + url;
        }
        if (!url.startsWith("/user")){
            url = "/user" + url;
        }
        return this.authenticatedRequest(this.USER_MANAGEMENT_API + url, opts);
    }

    public static async groupManagementRequest<T>(url:string, opts?:any): Promise<T>{
        if (!url.startsWith("/")){
            url = "/" + url;
        }
        if (!url.startsWith("/groups")){
            url = "/groups" + url;
        }
        return this.authenticatedRequest(this.USER_MANAGEMENT_API + url, opts);
    }

    public static async authenticatedRequest<T>(url: string, opts?: any): Promise<T> {
        if (!opts){
            opts = {};
        }

        if (opts["headers"]) {
            if (process.env.NODE_ENV !== 'production'){
                // Allow auth "spoofing" on dev
                opts["headers"]["Authorization"] = opts["headers"]["Authorization"] ?? CookieHelper.getCookieByName(CookieNames.id_token);
            } else {
                opts["headers"]["Authorization"] = CookieHelper.getCookieByName(CookieNames.id_token);
            }
        } else {
            opts["headers"] = {
                Authorization: CookieHelper.getCookieByName(CookieNames.id_token),
            };
        }
        return JSON.parse(await this.errorlessRequest<string>(url, opts, true)) as T;
    }

    public static async errorlessRequest<T>(url: string, opts: any = {}, useText = false): Promise<T> {
        if (opts.body){
            opts.method = opts.method ?? "post";
            if (typeof opts.body === "object"){
                opts.body = JSON.stringify(opts.body);
            }
        }
        const response: Response = (
            process.env.NODE_ENV !== 'production'// && !url.includes('http')
                ? await fetch((url.startsWith("/") ? `http://localhost${url}` : url), { ...opts })
                : await fetch((url.startsWith("/") ? `https://${window.location.hostname}${url}` : url), { ...opts })
        );

        if (!response.ok) {
            const errorMsg = `Encountered an error while calling url [${url}]: ${response.statusText}`;
            if (process.env.NODE_ENV !== 'production') {
                console.error(errorMsg);
            }
        }
        let res = (await (useText ? response.text() : response.json())) as T;
        return res
    };

    /**
     * TODO: Make functional with Backend API
     * 
     * Send a request to the analytics host.
     * @param url URL or path to send the request to 
     * @param opts Options to add to the request Ex: headers, body
     * @param useText Should the response be in plain text instead of JSON
     * @returns Response from the analytics host
     */
     public static async analyticsRequest<T>(url: string, opts: any = {}, useText = false): Promise<T> {
        
        if (!url.startsWith("/")){
            url = `/${url}`
        }
        if (!url.startsWith("/analytics")) {
            url = `/analytics${url}`;
        }


        if (opts.body && (typeof opts.body !== "string")){
            opts.body = JSON.stringify(opts.body);
        }

        if (!opts){
            opts = {};
        }

        if (!opts.headers){
            opts.headers = {};
        }

        if (!opts["method"]){
            opts["method"] = "post";
        }

        opts.headers.Authorization = CookieHelper.getCookieByName(CookieNames.id_token);
        try{
            // return new Promise<T>((r) => r({} as T));
            const response: Response | string = await fetch(`${AdminRequestHelper.ANALYTICS_HOST}${url}`, { ...opts }).catch((r) => "failed");
            if (typeof response === "string"){
                return {} as T;
            }
            if (!response.ok) {
                const errorMsg = `Encountered an error while calling url [${url}]: ${response.statusText}`;
                if (process.env.NODE_ENV !== 'production') {
                    console.error("(DEBUG", errorMsg);
                }
                throw Error(errorMsg);
            }
            let res = (await (useText ? response.text() : response.json())) as T;
            return res
        }catch(e){
            return {} as T;
        }
    };
}