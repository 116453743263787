import React from 'react';
import { BreadcrumbGroupProps, SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import { LEFT_NAV_FAVORITES } from 'utils/constants';
import { EventDispatcher, EventTypes, SessionHelper, StorageHelper, } from "@amzn/tux-static-website";
import {LeftNav as StaticLeftNav } from "@amzn/tux-static-website";

interface LeftNavProps{

}

export default class LeftNav extends StaticLeftNav{
    constructor(props: LeftNavProps){
        super(props);
        this.bindAll(this);
        EventDispatcher.subscribe(EventTypes.RoutingChange, LeftNav.RECENTS_KEY, this.addToRecents);
        // This is from constants since it's both the event key and the localStorage key
        EventDispatcher.subscribe(EventTypes.FavoritesChange, LEFT_NAV_FAVORITES, this.addToFavorites);
        EventDispatcher.subscribe(EventTypes.RecentsCleared, LeftNav.RECENTS_CLEARED_KEY, this.clearRecents);
    }

    public async loadData(){
        // throw new Error('Method not implemented.');
    }


    get navItems(): SideNavigationProps.Item[] {
        let output = this.defaultNavItems;

        if (process.env.NODE_ENV !== 'production'){
            output.push({
                type: "link",
                text: "Test (Remove for Go-Live)",
                href: `#/test`,
            });
        }

        output.push({
            type:"section",
            text:"Admin",
            items:[
                {
                    type:"link",
                    text:"New User",
                    href: `#/${SessionHelper.getRegion()}/admin/new_user`,
                },
                {
                    type:"link",
                    text:"Users",
                    href: `#/${SessionHelper.getRegion()}/admin/users`,
                },
                {
                    type:"link",
                    text:"Analytics",
                    href: `#/${SessionHelper.getRegion()}/admin/analytics`,
                },
            ]
        });

        return output;
    }

    static get globalFavorites(): SideNavigationProps.Link[] {
        return StorageHelper.getObject<SideNavigationProps.Link[]>(LEFT_NAV_FAVORITES, []) ?? [];
    }

    static get globalRecents(): SideNavigationProps.Link[] {
        return StorageHelper.getObject<SideNavigationProps.Link[]>(LeftNav.RECENTS_KEY, []) ?? [];
    }

    addToFavorites(route: BreadcrumbGroupProps.Item){
        super.addToFavorites(route);
        this.dataUpdated();
    }

    addToRecents(route: BreadcrumbGroupProps.Item){
        super.addToRecents(route);
        this.dataUpdated();
    }

    navChanged(e:any) {
        super.navChanged(e);
    }

    render() : JSX.Element{
        return (
            <SideNavigation 
                activeHref={window.location.pathname}
                header={{href: `#/${SessionHelper.getRegion()}/home`, text:"Home"}} 
                items={this.navItems} 
                className="leftNav"
                onChange={this.navChanged}
            />
        );
    }
}