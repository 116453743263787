import React from 'react';
import { TuxView, ValueWithLabel } from '@amzn/tux-static-website';
import AdminRequestHelper, { DailyErrorsSummary, DailyPageVisitsSummary, DailyVisitsSummary, MonthlyUsersResponse } from 'utils/admin_request_helper';
import { ColumnLayout, ExpandableSection, Grid, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import DelayedLoadExpandable from "../../../components/delayed_load_expandable";

interface AnalyticsViewProps{

}

export default class AnalyticsView extends TuxView<AnalyticsViewProps, any>{
    private isLoading: boolean = true;
    monthlyUsersSummary: MonthlyUsersResponse[] = [];
    dailyVisitsSummary: DailyVisitsSummary[] = [];
    dailyPageVisitsSummary: DailyPageVisitsSummary[] = [];
    dailyErrorsSummary: DailyErrorsSummary[] = [];

    constructor(props: AnalyticsViewProps){
        super(props);
        this.bindAll(this);
        this.loadData();
    }

    async loadData(){

        this.isLoading = false;
        this.dataUpdated();
    }

    private async loadDailyPageVisits(): Promise<{pages: DailyPageVisitsSummary[], visits: DailyVisitsSummary[]}> {

        let visits = await AdminRequestHelper.analyticsRequest<DailyVisitsSummary[]>("analytics/dailyVisits", {"method": "get"});
        let pages = await AdminRequestHelper.analyticsRequest<DailyPageVisitsSummary[]>("analytics/pageVisits", {"method": "get"});

        if (Array.isArray(visits)){
            visits.sort((a, b) => new Date(`${a._id.year}-${a._id.month}-${a._id.day}`).getTime()- new Date(`${b._id.year}-${b._id.month}-${b._id.day}`).getTime());
        } else {
            visits = [];
        }

        if (Array.isArray(pages)){
            pages.sort((a, b) => new Date(`${a._id.year}-${a._id.month}-${a._id.day}`).getTime()- new Date(`${b._id.year}-${b._id.month}-${b._id.day}`).getTime());
        }else { 
            pages = [];
        }


        return {pages, visits};
    }

    private async loadMonthlyUsers(): Promise<MonthlyUsersResponse[]> {
        let results: MonthlyUsersResponse[] = await AdminRequestHelper.analyticsRequest<MonthlyUsersResponse[]>("analytics/monthlyUsers", {"method": "get"});

        if (Array.isArray(results)){
            results.sort((a, b) => `${a._id.year}${a._id.month}`.localeCompare(`${b._id.year}${b._id.month}`));
        } else {
            results = [];
        }

        return results;
    }

    private async loadErrorSummary(): Promise<DailyErrorsSummary[]> {
        let results = await AdminRequestHelper.analyticsRequest<DailyErrorsSummary[]>("analytics/errorsPerDay", {"method": "get"});

        if (!Array.isArray(results)){
            return [];
        }

        return DailyErrorsSummary.fromJSON(results);
    }

    private getMonth(month:number): string{
        return [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ][month-1] ?? `${month}`;
    }

    private getAllUsers(summary: MonthlyUsersResponse){
        return (
            <ExpandableSection header={`${summary.users.length} unique users`}>
                <pre style={{maxHeight: "150px", overflowY: "auto"}}>
                    {
                        summary.users.filter((u) => u!==null && u.length>0).map((u) => 
                        `${u}\n`
                        )
                    }
                </pre>
            </ExpandableSection>
        )
    }

    private getNewUsers(summary: MonthlyUsersResponse, lastSummary: MonthlyUsersResponse){
        let newUsers = summary.users.filter((u) => !lastSummary.users.includes(u));

        return (
            <ExpandableSection header={`${newUsers.length} new users (vs last month)`}>
                <pre style={{maxHeight: "150px", overflowY: "auto"}}>
                    {
                        newUsers.filter((u) => u!==null && u.length>0).map((u) => 
                        `${u}\n`
                        )
                    }
                </pre>
            </ExpandableSection>
        )
    }

    private getSummary(summary: MonthlyUsersResponse, lastSummary?: MonthlyUsersResponse){
        return (
            <ValueWithLabel 
                key={`${summary._id.month}/${summary._id.year}`} 
                label={`${this.getMonth(summary._id.month)} ${summary._id.year}`}
                >
                    <Grid gridDefinition={[{colspan: 2},{colspan: 2}]}>
                        {this.getAllUsers(summary)}
                        {lastSummary? this.getNewUsers(summary, lastSummary) :null}
                    </Grid>
            </ValueWithLabel>
        )
    }

    private getMonthlySummary(monthlyUsersSummary: MonthlyUsersResponse[]){
        return (
            <>
                {monthlyUsersSummary.length > 0 ?
                    <SpaceBetween size="s">
                        {
                            monthlyUsersSummary.map((summary, index) => this.getSummary(summary, monthlyUsersSummary[index-1]))
                        }
                    </SpaceBetween>
                    :
                    "No Data Present"
                }
            </>
        )
    }

    private getDailySummary(summary: DailyVisitsSummary, pages: DailyPageVisitsSummary[]){

        return (
            <ExpandableSection key={`${summary._id.day}${summary._id.month}${summary._id.year}`} header={`${summary._id.day} ${this.getMonth(summary._id.month)} ${summary._id.year} - ${summary.visits} Visits`}>
                <pre style={{maxHeight: "150px", overflowY: "auto", minWidth: "150px"}}>
                    {
                        pages.length > 0 ? pages?.filter((s) => s._id.day === summary._id.day && s._id.month === summary._id.month && s._id.year === summary._id.year )
                        ?.map((s) => `${s._id.page} had ${s.count} visits`)?.join("\n") : "Failed to fetch breakdown"
                    }
                </pre>
            </ExpandableSection>
        )
    }
    
    private getDailyVisitsSummary(data: {pages: DailyPageVisitsSummary[], visits: DailyVisitsSummary[]}){
        
        return (
            <>
                {data.visits.length > 0 ?
                    <ColumnLayout columns={3}>
                        {
                            data.visits.map((summary) => this.getDailySummary(summary, data.pages))
                        }
                    </ColumnLayout>
                    :
                    "No Data Present"
                }
            </>
        )
    }
    
    
    private getErrorSummary(summary: DailyErrorsSummary){
        let i = 0;
        return (
            <ExpandableSection key={`${summary._id.day}${summary._id.month}${summary._id.year}`} header={`${summary._id.day} ${this.getMonth(summary._id.month)} ${summary._id.year} - ${summary.errors.length} Errors`}>
                {summary.errors.map((error)=>(
                    <ExpandableSection key={`ERROR_${i++}`} header={error.error}>
                        <pre style={{maxHeight: "150px", overflowY: "auto", minWidth: "150px"}}>
                            {JSON.stringify(error, null, 4)}
                        </pre>
                    </ExpandableSection>
                ))}
                
            </ExpandableSection>
        )
    }

    private getDailyErrorsSummary(errorSummaries: DailyErrorsSummary[]){
        return (
            <>
                {errorSummaries.length > 0 ?
                    <ColumnLayout columns={3}>
                        {
                            errorSummaries.map((summary) => this.getErrorSummary(summary))
                        }
                    </ColumnLayout>
                    :
                    "No Data Present"
                }
            </>
        )
    }

    render(){
        if (this.isLoading){
            return (
                <Spinner size="big"/>
            );
        }

        return (
            <SpaceBetween direction="vertical" size="m">
                <DelayedLoadExpandable<MonthlyUsersResponse[]> 
                    variant="container" 
                    title={"Monthly Summary (Past 12 Months)"}
                    loadData={this.loadMonthlyUsers} 
                    content={this.getMonthlySummary}
                />
                <DelayedLoadExpandable<{pages: DailyPageVisitsSummary[], visits: DailyVisitsSummary[]}> 
                    variant="container" 
                    title={"Daily Page Traffic (Past 14 Days)"}
                    loadData={this.loadDailyPageVisits} 
                    content={this.getDailyVisitsSummary}
                />
                <DelayedLoadExpandable<DailyErrorsSummary[]> 
                    variant="container" 
                    title={"Daily Error Summaries (Past 14 Days)"}
                    loadData={this.loadErrorSummary} 
                    content={this.getDailyErrorsSummary}
                />
            </SpaceBetween>
        );
    }
}