export enum CookieNames{
    id_token = "id_token",
    user_id = "ajs_user_id",
    anon_id = "ajs_anonymous_id",
    access_token = "access_token",
    group_id = "ajs_group_id",
}

export default class CookieHelper{
    private static COOKIES: {[key:string]:string} = {};
 
    /**
     * Fetches the access token from the cookies
     */
    static get AccessToken(){
        return CookieHelper.getCookieByName(CookieNames.access_token);
    }

    /**
     * Fetch a cookie by name
     * @param name Name of the cookie
     * @returns cookie contents if present, '' if not
     */
    static getCookieByName(name: string): string{
        if (process.env.NODE_ENV !== 'production'){
            if (name === CookieNames.id_token){
                return `AAA.${btoa(JSON.stringify({"given_name": "Test","identities": [{"userId": "Test"}],"family_name": "User","email": "test@amazon.com"}))}.AAA`;
            }
    
            return "";
        }

        if (CookieHelper.COOKIES[name]){
            return CookieHelper.COOKIES[name];
        }

        let cookie = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
        CookieHelper.COOKIES[name] = cookie;
        return cookie;
    }
}